import {AfterViewInit, Component, DoCheck, Input, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RefreshDataService} from 'synto-common';
import {DialogComponent} from "../dialog/dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'fb-accordion',
  templateUrl: './fb-accordion.component.html',
  styleUrls: ['./fb-accordion.component.scss']
})
export class FbAccordionComponent implements OnInit, AfterViewInit, DoCheck {
  @Input() selectedLanguage: any;
  @Input() selectedSectionId: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() isFormbuilderModeEnabled: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() offlineModeEnabled;
  @Input() blockIndex: number;
  @Input() sections;
  containerConfChange: any;
  differ: KeyValueDiffer<string, any>;


  constructor(private translate: TranslateService, private refreshDataService: RefreshDataService,
              public dialog: MatDialog,
              private differs: KeyValueDiffers) {
    this.confChange = {};
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.bphChange.rows = [];
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.differ = this.differs.find({}).create();
  }

  ngDoCheck(): void {
    const change = this.differ.diff(this.bphChange);
    if (change) {
      this.ngAfterViewInit();
    }
  }

  ngOnInit(): void {
    this.containerConfChange = this.confChange;
    if (!this.confChange.noOfAccordions) {
      this.confChange.noOfAccordions = 1;
    } else {
      console.log("starting init of accordeon!!!!!");
      if (isNaN(this.confChange.noOfAccordions)) {
        const globalPrj = this.globalPrj;
        const confChange = this.confChange;
        const globalConf = this.globalConf;
        const globalComponent = this;
        this.confChange.noOfAccordions = Number(new Function(`
        try {
            return (this.${this.confChange.noOfAccordions.trim()});
        } catch (e) {
            console.error(e);
            return 1;
        }`).call({globalPrj, confChange, globalConf, globalComponent}));
        this.bphChange.noOfAccordions = this.confChange.noOfAccordions;
      } else {
        this.bphChange.noOfAccordions = this.confChange.noOfAccordions;
      }
    }
    if (!this.bphChange.rows || this.bphChange.rows.length === 0) {
      this.bphChange.rows = Array.from(Array(this.confChange.noOfAccordions).keys()).map(() => {
        const obj = {};
        this.confChange.subBlocks.forEach(subBlock => {
          obj[subBlock.name] = {
            'globalShowDetail': true
          }
        });
        return obj;
      });
    } else {
      this.bphChange.rows.map(row => {
        this.confChange.subBlocks.forEach(subBlock => {
          if (!row[subBlock.name]) {
            row[subBlock.name] = {};
          }
          row[subBlock.name]['globalShowDetail'] = true;
        });
        return row;
      });
    }
    this.bphChange.selectedRow = this.bphChange.rows.length > 0 ? this.bphChange.rows[0] : {};
    this.bphChange.selectedRow.index = 0;
    if (!this.confChange.isAccordionLabelParametrized) {
      this.bphChange.rows.map(row => {
        row.accordionLabel = this.confChange?.accordionLabel[this.selectedLanguage];
        return row;
      })
    }

  }

  getAccordionLabel(rowIndex: number): string {
    if (!this.confChange.accordionLabel) {
      return '';
    }
    const globalPrj = this.globalPrj;
    const confChange = this.confChange;
    const globalConf = this.globalConf;
    const globalComponent = this;
    const label = new Function(`
        try {
            return (this.${this.confChange.accordionLabel.replace('index', rowIndex).trim()});
        } catch (e) {
            console.error(e);
            return '';
        }`).call({globalPrj, confChange, globalConf, globalComponent});
    this.bphChange.rows[rowIndex].accordionLabel = label;
    return label;
  }


  openDialog(): void {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data:
        {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
  }

  ngAfterViewInit(): void {
    this.confChange.subBlocks = this.confChange.subBlocks.map((subBlock, index) => {
      subBlock.templateName = subBlock.type;
      subBlock.templateContext = {
        'bphChange': this.bphChange,
        'confChange': this.globalConf[this.selectedSectionId][this.confChange.name][subBlock.name],
        'blockName': subBlock.name,
        'isValidHtml': true,
        'isValidTable': false,
        'isValidTableReadonly': false,
        'isLast': ((this.confChange.subBlocks.length - 1) === index)
      };
      return subBlock;
    });
  }

  executeFunction(rowIndex, confChange, bphChange): void {
    console.log("trigger change accordeon");
    for (const functionName of confChange.updateOutputEventFunctions) {
      if (confChange[functionName]) {
        confChange[functionName](bphChange.value, rowIndex, this.globalPrj, this.globalConf, this.selectedLanguage, this.refreshDataService);
      }
    }
  }

  doNothing(): void {
  }


}

import {AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {MatDialog} from '@angular/material/dialog';
import {DialogComponent} from '../dialog/dialog.component';
import {EcaseHttpService, ECaseSnackBarService, ECaseUtils, PendingChangesGuard} from 'synto-common';
import {
  AttachExistingDocumentDialogComponent
} from './attach-existing-document-dialog/attach-existing-document-dialog.component';

@Component({
  selector: 'fb-upload',
  templateUrl: './fb-upload.component.html',
  styleUrls: ['./fb-upload.component.scss']
})
export class FbUploadComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() bphChange: any;
  @Input() confChange: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() globalConf: any;
  @Input() globalPrj: any;
  @Input() selectedLanguage;
  @Input() offlineModeEnabled;
  @Input() selectedSectionId;
  @Input() formId;
  @Input() blockIndex: number;
  @Output() emitOutputEvent = new EventEmitter<any>();
  @Input() rowNumber: number = -1;

  lang: any;
  row: any;
  isvalue: boolean;
  isMultipleFilesStored: boolean;
  isMultipleFilesBeingStored: boolean;
  tvalue: boolean;
  arrayofFiles: { id: number; fileName: string; fileUploaded: boolean; creation_date: any }[];
  validExtensionsString = '';
  randomNumber: number;
  ifConditionallyRequired = false;
  defaultFormatType = [];
  focus = false;


  constructor(private translate: TranslateService,
              private http: EcaseHttpService,
              private eCaseSnackBarService: ECaseSnackBarService,
              public pendingChangesGuard: PendingChangesGuard,
              public dialog: MatDialog) {
    this.defaultFormatType = [{id: 372, value: '.pdf'}, {id: 371, value: '.doc'}, {id: 373, value: '.docx'}, {
      id: 370,
      value: '.ppt'
    }];
  }


  ngOnInit(): void {
    console.log('This is the selected language');
    console.log(this.selectedLanguage);
    console.log(this.confChange.formatTypes)
    if (!this.bphChange) {
      this.bphChange = {};
    }
    if (!this.bphChange.error) {
      this.bphChange.error = {};
    }
    if (!this.bphChange.value) {
      this.bphChange.value = [];
    }
    if (this.bphChange.value === '') {
      this.bphChange.value = [];
    }
    if (!this.bphChange.source) {
      this.bphChange.source = {};
    }
    this.bphChange.getValue = (): any => this.bphChange.value;
    if (!this.confChange) {
      this.confChange = {};
      this.confChange.label = {};
    }
    this.row = {};
    this.defaultFormatType = [{id: 372, value: '.pdf'}, {id: 371, value: '.doc'}, {id: 373, value: '.docx'}, {
      id: 370,
      value: '.ppt'
    }];
    this.ifConditionallyRequired = this.validateConditionAsterisk();
    this.randomNumber = Math.floor((Math.random() * 1000) + 1);
    if (!this.confChange.formatTypes) {
      this.confChange.formatTypes = this.defaultFormatType;
    }

    if (!this.bphChange.value.id) {
      this.tvalue = false;
    } else {
      this.isMultipleFilesStored = Array.isArray(this.bphChange.value.id);
    }
    if (!this.confChange.isMultipleFileUploadAllowed) {
      this.confChange.isMultipleFileUploadAllowed = false;
    }

    this.isMultipleFilesBeingStored = this.bphChange.value.length <= 0;

    if (this.confChange.staticDocumentType !== 2) {
      this.bphChange.documentType = this.confChange.staticDocumentType;
    }
    /*this.translate.onLangChange.subscribe((params: LangChangeEvent) => {
      this.selectedLanguage = params.lang;

    });*/
    //  this.bphChange.value.fileUploaded = false;
    this.confChange.uploadDone = (data, currentValue, localConf): void  => {
      currentValue.value = data;
    };

    this.confChange.initValue = (currentValue, localConf): void  =>  {
      currentValue.value = {};
      currentValue.error = {};
      currentValue.value.fileUploaded = false;
      currentValue.getValue = (): any => currentValue.value;
    };
    if (!this.confChange.isReadOnlyField) {
      this.confChange.isReadOnlyField = false;
    }
    if (!this.bphChange.globalReadOnlyMode) {
      this.bphChange.globalReadOnlyMode = false;
    }

    //  console.log(this.bphChange.value.fileUploaded);
    if (this.confChange.tooltipText) {
      this.confChange.tooltipText[this.selectedLanguage] = ECaseUtils.encodeStringToUTF8String(this.confChange.tooltipText[this.selectedLanguage]);
    }
  }

  validateConditionAsterisk(): boolean {
    if (this.confChange.isRequiredConditional) {
      const sectionName = this.confChange.requiredConditionalComponent.sectionName.toString();
      return this.globalPrj[sectionName].hide !== true;
    } else {
      return false;
    }
  }

  openDialog(): void {
    this.dialog.open(DialogComponent, {
      width: '600px',
      data: {
          dialog: this.confChange.dialogText,
          selectedLanguage: this.selectedLanguage
        }
    });
  }

  getTranslatedLabel(key: string): string {
    return ECaseUtils.getTranslatedValueFromKey(this.translate, key, this.selectedLanguage);
  }

  uploadDone(data, currentValue, conf): void {
    if (this.offlineModeEnabled) {
      this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.form.offlineMessage'));
    } else {
      if (!this.globalPrj.docsIds) {
        this.globalPrj.docsIds = [];
      }

      const documentPath = data.target.value;
      let isExtValid = false;
      for (let i = 0; i < this.confChange.typesAdmin.length; i++) {
        console.log(this.confChange.typesAdmin[i]);
        if (documentPath.includes(this.confChange.typesAdmin[i].toLowerCase())) {
          isExtValid = true;
          break;
        }
      }

      if (isExtValid === false) {
        const msg = ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.common.uploadRequiredExtensionsMessage', this.selectedLanguage) + this.confChange.typesAdmin;
        alert(msg);
      }

      let invalidFlag = false;
      if (data.target.files) {
        for (const file of data.target.files) {
          if (file.size > (this.confChange.sizeAdmin * 1048576)) {
            alert(this.selectedLanguage === 'en' ? 'the size of the file is too big' : 'la taille du fichier est trop importante');
            invalidFlag = true;
            break;
          }
        }
      }

      if (!invalidFlag && isExtValid) {
        const formData = new FormData();
        let fileName = '';
        for (const file of data.target.files) {
          fileName = file.name;
          console.log(fileName);
          formData.append(file.name, file);
        }

        this.bphChange.documentType = this.confChange.staticDocumentType !== 2 ? this.confChange.staticDocumentType : this.bphChange.documentType;

        if (this.bphChange.documentType) {
          //  console.log(this.bphChange.documentType);
          // console.log(formData);
          this.blockIndex = this.blockIndex ?this.blockIndex : 0;
          formData.append('documentTypeId', this.bphChange.documentType);
          formData.append('selectedSectionId', this.selectedSectionId ? this.selectedSectionId : this.confChange.selectedSection.id);
          formData.append('formId', this.formId ? this.formId : this.globalPrj.formId);
          formData.append('blockIndex', this.blockIndex.toString());
          console.log(this.bphChange.documentType);
          this.http.post('/api/fileUpload', formData).subscribe({
            next: (_data: any) => {
              const result = _data;
              if (result.hasOwnProperty('status') && !result.status) {
                alert(this.selectedLanguage === 'en' ? 'Upload failed' : 'Le téléchargement a échoué');
              } else {

                if (result.docsIds.length > 1) {
                  for (let i = 0; i < result.docsIds.length; i++) {
                    console.log(result);
                    const uploadId = result.docsIds[i];
                    const uploadName = result.docsNames[i];
                    const noOfPages = result.noOfPages[i];
                    const tempObject = {
                      'id': uploadId,
                      'fileName': uploadName,
                      'fileSize': data.target.files[i].size,
                      'noOfPages': noOfPages ? noOfPages[uploadId.toString()] : 0,
                      'fileUploaded': true,
                      'creation_date': new Date()
                    };
                    this.bphChange.value.push(tempObject);

                  }
                }
                console.log(this.bphChange);

                if (result.docsIds.length === 1) {
                  //  this.bphChange.value.id = result.docsIds;
                  //  this.bphChange.value.fileName = result.docsNames;
                  const uploadId = result.docsIds[0];
                  const uploadName = result.docsNames[0];
                  const noOfPages = result.noOfPages[0];
                  console.log(uploadName);
                  const parsedUploadName = uploadName.substring(uploadName.lastIndexOf('\\') + 1);
                  console.log(parsedUploadName);
                  const tempObject = {
                    'id': uploadId,
                    'fileName': parsedUploadName,
                    'fileUploaded': true,
                    'fileSize': data.target.files[0].size,
                    'noOfPages': noOfPages ? noOfPages[uploadId.toString()] : 0,
                    'creation_date': new Date()
                  };
                  console.log(tempObject);
                  this.bphChange.value.push(tempObject);
                  console.log('First condition');
                }
                // else {
                //   let upload_id = result.docsIds[0];
                //   let upload_name = result.docsNames[0];
                //   let tempObject = {'id': upload_id, 'fileName': upload_name, 'fileUploaded': true, 'creation_date': new Date()};
                //
                //   this.bphChange.value.push(tempObject);
                //   console.log('Second condition');
                // }

                this.tvalue = false;


                this.globalPrj.docsIds = [...this.globalPrj.docsIds, ...result.docsIds];
                console.log('samirarrrrrrrrrrrrrrr');
                console.log(this.globalPrj.docsIds);

                this.isMultipleFilesBeingStored = this.confChange.isMultipleFileUploadAllowed;
                this.eCaseSnackBarService.show('success', this.selectedLanguage === 'en' ? 'File(s) uploaded' : 'Fichier téléchargé');
                this.pendingChangesGuard.isPristine = false;
                console.log('BEFOREEMITTING2');
                if (this.confChange.enableOutputEvent) {
                  console.log('EMITTING2');
                  this.emitOutputEvent.emit({
                    'bphChange': this.bphChange,
                    'confChange': this.confChange,
                    'prj': this.globalPrj,
                    'conf': this.globalConf
                  });
                }
              }
            },
            error: (error) => {
              this.eCaseSnackBarService.show('failure', error.errorMessage);
            }
          });
        } else {
          alert(this.selectedLanguage === 'en' ? 'Please select the document type before uploading' : 'Veuillez sélectionner le type de document');
        }
      }
    }
    this.focus = false;
  }

  openExistingDocumentDialog(): void {
    const dialogRef = this.dialog.open(AttachExistingDocumentDialogComponent);
    dialogRef.componentInstance.lang = this.selectedLanguage;
    dialogRef.componentInstance.documents = (this.confChange.lovSQL && this.globalConf.lovs[this.confChange.lovSQL.name])
      ? (this.globalConf.lovs[this.confChange.lovSQL.name]).list : [];
    dialogRef.afterClosed().subscribe((document) => {
      if (document) {
        this.bphChange.documentType = this.confChange.staticDocumentType !== 2 ? this.confChange.staticDocumentType : this.bphChange.documentType;
        const obj = {
          'documentTypeId' : this.bphChange.documentType,
          'selectedSectionId' : this.selectedSectionId ? this.selectedSectionId : this.confChange.selectedSection.id,
          'formId' : this.formId ? this.formId : this.globalPrj.formId,
          'blockIndex': this.blockIndex !== undefined && !isNaN(Number(this.blockIndex)) ? Number(this.blockIndex) : 0,
          'docIds' : document,
        };
        this.http.post('/api/attachExistingDocument', obj).subscribe({
          next: (_data: any) => {
            const result = _data;
            if (result.hasOwnProperty('status') && !result.status) {
              alert(this.selectedLanguage === 'en' ? 'Upload failed' : 'Le téléchargement a échoué');
            } else {
              this.tvalue = false;
              const uploadId = result.docsIds[0];
              const uploadName = result.docsNames[0];
              const noOfPages = result.noOfPages[0];
              const parsedUploadName = uploadName.substring(uploadName.lastIndexOf('\\') + 1);
              const tempObject = {
                'id': uploadId,
                'fileName': parsedUploadName,
                'fileUploaded': true,
                'fileSize': result.fileSize[0],
                'noOfPages': noOfPages ? noOfPages[uploadId.toString()] : 0,
                'creation_date': new Date()
              };
              this.bphChange.value.push(tempObject);
              this.globalPrj.docsIds = this.globalPrj.docsIds ? [...this.globalPrj.docsIds, ...result.docsIds] : [...result.docsIds];
              console.log(this.globalPrj.docsIds);
              this.isMultipleFilesBeingStored = this.confChange.isMultipleFileUploadAllowed;
              this.eCaseSnackBarService.show('success', this.selectedLanguage === 'en' ? 'File(s) uploaded' : 'Fichier téléchargé');
              this.pendingChangesGuard.isPristine = false;
              if (this.confChange.enableOutputEvent) {
                this.emitOutputEvent.emit({
                  'bphChange': this.bphChange,
                  'confChange': this.confChange,
                  'prj': this.globalPrj,
                  'conf': this.globalConf
                });
              }
            }
          },
          error: (error) => {
            this.eCaseSnackBarService.show('failure', error.errorMessage);
          }
        });
      }
    });
  }

  isTableClicked($event): void {
    const name = this.confChange.name;
    if (this.confChange.isUploadExistingDocument) {
      this.openExistingDocumentDialog();
    } else {
      console.log('isTabelClicked function called.');
      if (this.isValidTable && !this.row.isTotal) {
        console.log('Random Number');
        const element: HTMLElement = document.querySelector('#' + name + this.randomNumber);
        if (element) {
          element.click();
        }
      } else {
        console.log('No Random Number');
        console.log(name);
        const element: HTMLElement = document.querySelector('#' + name + '_' + this.rowNumber);
        console.log(this.bphChange);
        console.log(element);
        if (element) {
          console.log('//--// element exists //--//');
          // (<HTMLInputElement>document.querySelector('#' + name + '_' + this.rowNumber)).value = '';
          $event.stopPropagation();
          element.click();
        }
      }
    }
  }

  test($event): void {
    if (this.confChange.isUploadExistingDocument) {
      this.openExistingDocumentDialog();
    } else {
      console.log('HELOELOELOELOELE=====================**************=');
      const name = this.confChange.name;
      // (<HTMLInputElement>document.querySelector('#' + name)).value = '';
    }
  }


  deleteFile(fileUploaded, index): void {
    console.log('form delete file ');
    console.log(this.arrayofFiles);
    console.log(fileUploaded);
    console.log(index);
    console.log(this.globalPrj);
    console.log(this.confChange);

    if (!this.globalPrj.upload_to_be_deleted) {
      this.globalPrj.upload_to_be_deleted = [];
    }
    if (index === -999) {
      this.globalPrj.upload_to_be_deleted.push(fileUploaded.id);
      console.log(this.bphChange.value);
      this.bphChange.value.splice(index, 1);
      this.tvalue = true;
      this.bphChange.documentType = undefined;
    } else {
      this.globalPrj.upload_to_be_deleted.push(fileUploaded.id);
      this.bphChange.value.splice(index, 1);
      console.log(this.bphChange.value);
      if (this.bphChange.value.length === 1) {
        this.tvalue = false;
      }
      if (this.bphChange.value.length === 0) {
        this.tvalue = true;
      }
    }

    if (this.confChange.isMultipleFileUploadAllowed === false) {
      this.isMultipleFilesBeingStored = true;
    }


    if (this.globalPrj.docsIds && this.globalPrj.docsIds.length > 0) {
      this.globalPrj.docsIds = this.globalPrj.docsIds.filter(x => !this.globalPrj.upload_to_be_deleted.includes(x));
    }

    this.focus = false;
    console.log('BEFOREEMITTING');
    if (this.confChange.enableOutputEvent) {
      console.log('EMITTING1');
      this.emitOutputEvent.emit({
        'bphChange': this.bphChange,
        'confChange': this.confChange,
        'prj': this.globalPrj,
        'conf': this.globalConf
      });
    }
  }

  checkIsSaved(): boolean {
    if (!this.pendingChangesGuard.isPristine) {
      this.eCaseSnackBarService.show('failure', ECaseUtils.getTranslatedValueFromKey(this.translate, 'ecase.formbuilder.uploadComponent.notSavedDoc.error', this.selectedLanguage));
      return false;
    }
  }

  parentDivOnClick(): void {
    /*if(this.isValidTable && !this.row.isTotal){
      console.log('Random Number');
      const element: HTMLElement = document.querySelector(this.confChange.name + this.randomNumber);
      if (element) {
        element.click();
      }
    }
    else{
      console.log('no random Number');
      const element: HTMLElement = document.querySelector('upload');
      console.log(this.confChange);
      console.log(element);
      // document.querySelector('#testButton').addEventListener('click', function(event){
      //   console.log('aaaaaaaaaa');
      //   console.log(event);
      //
      // });
      // console.log(scrollBar);
      if(element){
        element.click();
      }

    }*/

  }

  focusInFunction(): void {
    this.focus = true;

  }

  focusOutFunction(): void {
    this.focus = false;

  }

  ngOnDestroy(): void {
    // this.bphChange.value = [];
  }


  ngAfterViewInit(): void {
    // this.getAllInputs();
  }

}

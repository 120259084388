import {AfterViewInit, Component, DoCheck, Input, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {RefreshDataService} from 'synto-common';

@Component({
  selector: 'fb-container',
  templateUrl: './fb-container.component.html',
  styleUrls: ['./fb-container.component.css']
})
export class FbContainerComponent implements OnInit, AfterViewInit, DoCheck {
  @Input() selectedLanguage: any;
  @Input() selectedSectionId: any;
  @Input() isValidHtml: boolean;
  @Input() isValidTable: boolean;
  @Input() isValidTableReadonly: boolean;
  @Input() isFormbuilderModeEnabled: boolean;
  @Input() bphChange: any;
  @Input() globalPrj: any;
  @Input() confChange: any;
  @Input() globalConf: any;
  @Input() offlineModeEnabled;
  @Input() blockIndex: number;
  @Input() sections;
  containerConfChange: any;
  differ: KeyValueDiffer<string, any>;


  constructor(private translate: TranslateService, private refreshDataService: RefreshDataService, private differs: KeyValueDiffers) {
    this.confChange = {};
    this.bphChange = {};
    this.bphChange.value = {};
    this.bphChange.value.label = {};
    this.confChange.label = {};
    this.bphChange.error = {};
    this.bphChange.source = {};
    this.isValidHtml = true;
    this.isValidTable = false;
    this.isValidTableReadonly = false;
    this.differ = this.differs.find({}).create();
  }

  ngDoCheck(): void {
    const change = this.differ.diff(this.bphChange);
    if (change) {
      this.ngAfterViewInit();
    }
  }

  ngOnInit(): void {
    this.containerConfChange = this.confChange;

  }

  ngAfterViewInit(): void {
    this.confChange.subBlocks = this.confChange.subBlocks.map((subBlock, index) => {
      subBlock.templateName = subBlock.type;
      subBlock.templateContext = {
        'bphChange': this.bphChange[subBlock.name],
        'confChange': this.globalConf[this.selectedSectionId][this.confChange.name][subBlock.name],
        'blockName': subBlock.name,
        'isValidHtml': true,
        'isValidTable': false,
        'isValidTableReadonly': false,
        'isLast': ((this.confChange.subBlocks.length - 1) === index)
      };
      return subBlock;
    });
  }

  executeFunction(confChange, bphChange): void {
    for (const functionName of confChange.updateOutputEventFunctions) {
      if (confChange[functionName]) {
        confChange[functionName](bphChange.value, this.globalPrj, this.globalConf, this.selectedLanguage, this.refreshDataService);
      }
    }
  }

  doNothing(): void {
  }


}
